import * as yup from "yup";

export const contactUsSchema = yup.object({
  name: yup.string().label("Company Name").required("Name is required"),
  email: yup
    .string()
    .label("Email")
    .email("Invalid email address")
    .required("Email is required"),
  phone: yup
    .string()
    .label("Phone")
    .nullable()
    .test(
      "is-valid-phone",
      "Invalid phone number",
      (value) => !value || /^\+?[1-9]\d{9,14}$/.test(value)
    ),
  subject: yup.string().label("Subject").required("Subject is required"),
  message: yup.string().label("Message"),
});
