import React from "react";
import { Helmet } from "react-helmet-async";
import metadata from "../seoMetadata.json"; // Import the JSON

const SEO = ({ page }) => {
  const pageData = metadata[page] || {}; // Get metadata for the specific page

  return (
    <Helmet>
      <title>{pageData.title}</title>
      <meta name="description" content={pageData.meta?.description} />
      <meta name="keywords" content={pageData.meta?.keywords?.join(", ")} />

      {/* Canonical Link */}
      <link rel="canonical" href={pageData.canonical} />

      {/* Open Graph Tags */}
      <meta property="og:title" content={pageData.openGraph?.title} />
      <meta
        property="og:description"
        content={pageData.openGraph?.description}
      />
      <meta property="og:url" content={pageData.openGraph?.url} />
      <meta property="og:type" content={pageData.openGraph?.type} />
      <meta property="og:image" content={pageData.openGraph?.image} />

      {/* Twitter Cards */}
      <meta name="twitter:card" content={pageData.twitter?.card} />
      <meta name="twitter:title" content={pageData.twitter?.title} />
      <meta
        name="twitter:description"
        content={pageData.twitter?.description}
      />
      <meta name="twitter:image" content={pageData.twitter?.image} />

      {/* Schema Markup (if available) */}
      {pageData.schema && (
        <script type="application/ld+json">
          {JSON.stringify(pageData.schema)}
        </script>
      )}
    </Helmet>
  );
};

export default SEO;
