import React from "react";
import SEO from "../Seo";
import { Container, Box, Typography } from "@mui/material";

function PrivacyPolicy() {
  return (
    <>
      <SEO page="home" /> {/* Load metadata for the home page */}
      <Box component="section" className="policy-content" py={5}>
        <Container>
          <Typography className="page-heading" variant="h1" mb={3}>
            Privacy Policy
          </Typography>
          <Typography variant="body1" mb={2}>
            <strong>Last Updated:</strong> Dec. 09, 2024
          </Typography>
          <Typography variant="body1" mb={2}>
            TaskBoard ("we," "our," or "us") is committed to protecting the
            privacy of our users ("you" or "your"). This Privacy Policy explains
            how we collect, use, disclose, and safeguard your information when
            you visit our website or use our services. Please read this policy
            carefully. If you do not agree with the terms of this Privacy
            Policy, please do not access the website or services.
          </Typography>
          <Typography variant="h4" mb={2}>
            1. Information We Collect
          </Typography>
          <Typography variant="body1" mb={2}>
            We may collect the following types of information when you use our
            services:
          </Typography>
          <Typography variant="h4" mb={2}>
            1.1 Personal Information:
          </Typography>
          <Typography variant="body1" mb={2}>
            <ul>
              <li>Name</li>
              <li>Email Address</li>
              <li>Phone Number</li>
              <li>Payment Information (e.g., credit card details)</li>
            </ul>
          </Typography>
          <Typography variant="h4" mb={2}>
            1.2 Non-Personal Information:
          </Typography>
          <Typography variant="body1" mb={2}>
            <ul>
              <li>Device Information (e.g., browser type, operating system)</li>
              <li>Usage Data (e.g., pages visited, time spent on pages)</li>
              <li>IP Address</li>
            </ul>
          </Typography>
          <Typography variant="h4" mb={2}>
            1.3 Cookies and Tracking Technologies:
          </Typography>
          <Typography variant="body1" mb={2}>
            We use cookies to improve your experience, analyze website traffic,
            and personalize content. You can control cookies through your
            browser settings.
          </Typography>
          <Typography variant="h4" mb={2}>
            2. How We Use Your Information
          </Typography>
          <Typography variant="body1" mb={2}>
            We use the information we collect to:
          </Typography>
          <Typography variant="body1" mb={2}>
            <ul>
              <li>Provide and maintain our services</li>
              <li>
                Process transactions, including our fixed subscription fee of
                <strong> $49.99/month</strong> or <strong>$499.99/year</strong>
              </li>
              <li>Communicate updates and promotional offers</li>
              <li>Improve our website and services</li>
              <li>Respond to customer inquiries and provide support</li>
              <li>Ensure compliance with legal obligations</li>
            </ul>
          </Typography>
          <Typography variant="h4" mb={2}>
            3. Sharing Your Information
          </Typography>
          <Typography variant="body1" mb={2}>
            We do not sell or rent your personal information. However, we may
            share information with:
          </Typography>
          <Typography variant="body1" mb={2}>
            <ul>
              <li>
                <strong>Service Providers:</strong> Third-party vendors
                assisting in operations, such as payment processing for our
                fixed pricing plans.
              </li>
              <li>
                <strong>Legal Compliance:</strong> Authorities if required to
                comply with legal obligations or protect against fraud.
              </li>
            </ul>
          </Typography>
          <Typography variant="h4" mb={2}>
            4. Data Retention
          </Typography>
          <Typography variant="body1" mb={2}>
            We retain your information only as long as necessary to fulfill the
            purposes outlined in this Privacy Policy, including processing and
            managing your fixed monthly or annual subscription payments.
          </Typography>
          <Typography variant="h4" mb={2}>
            5. Data Security
          </Typography>
          <Typography variant="body1" mb={2}>
            We implement industry-standard security measures to protect your
            information. However, no method of transmission over the Internet or
            electronic storage is 100% secure.
          </Typography>
          <Typography variant="h4" mb={2}>
            6. Your Rights
          </Typography>
          <Typography variant="body1" mb={2}>
            You have the right to:
          </Typography>
          <Typography variant="body1" mb={2}>
            <ul>
              <li>Access, update, or delete your personal data</li>
              <li>Opt-out of receiving promotional communications</li>
              <li>Restrict data processing under certain circumstances</li>
            </ul>
          </Typography>
          <Typography variant="h4" mb={2}>
            7. International Data Transfers
          </Typography>
          <Typography variant="body1" mb={2}>
            If you are accessing TaskBoard from outside [Your Country], please
            note that your data may be transferred and stored in a country with
            different privacy laws.
          </Typography>
          <Typography variant="h4" mb={2}>
            8. Changes to This Policy
          </Typography>
          <Typography variant="body1" mb={2}>
            We may update this Privacy Policy periodically. If changes affect
            pricing-related information, we will notify you accordingly.
          </Typography>
        </Container>
      </Box>
    </>
  );
}

export default PrivacyPolicy;
