import React from "react";
import "./header.css";
import { useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Container, Drawer, IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Grid2";
import { Link } from "react-router-dom";

import Logo from "../../assets/svg/logo.svg";
import Button from "../Button";
import SeeDemoComp from "../../components/SeeDemo";
import SignUpCommonDialog from "../singUpForm/SignUpCommonDialog";
import { useCustomParams } from "../../hooks/useCustomParams";

import CommonDialog from "../../components/dialog/CommonDialog";
import ThankYouDemoDialog from "../../components/SeeDemo/ThankYouDemoDialog";
import { clickEvent } from "../../utils/helpers/createEvent";

const Header = () => {
  const [signupModal, setSignupModal] = React.useState(false);
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [isDefaultOpenDialog] = useCustomParams("signup");

  const [open, setOpen] = React.useState(false);
  const [thankYou, setThankYou] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
    const eventData = {
      category: "See Demo Form",
      action: `Clicked on See a Demo.`,
      debug_mode: true,
    };

    clickEvent(eventData);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (isDefaultOpenDialog === "open") setSignupModal(true);
  }, [isDefaultOpenDialog]);

  const location = useLocation();
  const isActive = (hash) => location.hash === hash;

  useEffect(() => {
    if (location.state?.scrollTo === "pricing") {
      const pricingSection = document.getElementById("pricing");
      if (pricingSection) {
        const yOffset = -70;
        const yPosition =
          pricingSection.getBoundingClientRect().top +
          window.pageYOffset +
          yOffset;

        window.scrollTo({ top: yPosition, behavior: "smooth" });
      }
    }
  }, [location.state]);

  return (
    <>
      <header className="header" id="header">
        <Container>
          <Grid container spacing={2} alignItems="center">
            <Grid size={12} className="header-bar">
              <div className="logo">
                <Link to="/">
                  <img src={Logo} alt="Logo" />
                </Link>
              </div>
              <div className="header-right">
                <nav className="nav-links">
                  <div className="nav-links-left">
                    <NavLink
                      to="/about-us"
                      className={({ isActive }) =>
                        isActive ? "activeLink" : ""
                      }
                      onClick={() => {
                        window.scrollTo({ top: 0, behavior: "smooth" });
                      }}
                    >
                      About Us
                    </NavLink>
                    <NavLink
                      to="/#pricing"
                      state={{ scrollTo: "pricing" }}
                      className={isActive("#pricing") ? "activeLink" : ""}
                    >
                      Pricing
                    </NavLink>
                    <NavLink
                      to="/blog"
                      className={({ isActive }) =>
                        isActive ? "activeLink" : ""
                      }
                      onClick={() => {
                        window.scrollTo({ top: 0, behavior: "smooth" });
                      }}
                    >
                      Blog
                    </NavLink>
                    <NavLink
                      to="/contact"
                      className={({ isActive }) =>
                        isActive ? "activeLink" : ""
                      }
                      onClick={() => {
                        window.scrollTo({ top: 0, behavior: "smooth" });
                      }}
                    >
                      Contact
                    </NavLink>
                  </div>
                  <div className="nav-right">
                    <SignUpCommonDialog
                      defaultOpen={signupModal}
                      title={"Sign Up for free"}
                      className={"custom-btn"}
                    ></SignUpCommonDialog>
                  </div>
                </nav>

                <IconButton
                  className="hamburger-menu"
                  onClick={() => setDrawerOpen(true)}
                >
                  <MenuIcon />
                </IconButton>
              </div>
            </Grid>
          </Grid>
        </Container>
      </header>

      {/* Drawer for Mobile Menu */}
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
      >
        <div className="drawer-content">
          <IconButton
            onClick={() => setDrawerOpen(false)}
            className="close-button"
          >
            <CloseIcon />
          </IconButton>
          <nav className="drawer-nav-links">
            <NavLink
              to="/about-us"
              className={({ isActive }) => (isActive ? "activeLink" : "")}
              onClick={() => {
                setDrawerOpen(false);
                window.scrollTo({ top: 0, behavior: "smooth" });
              }}
            >
              About Us
            </NavLink>
            <Link
              to="/"
              state={{ scrollTo: "pricing" }}
              onClick={() => setDrawerOpen(false)}
            >
              Pricing
            </Link>
            <Link
              to="blog"
              className={({ isActive }) => (isActive ? "activeLink" : "")}
              onClick={() => {
                setDrawerOpen(false);
                window.scrollTo({ top: 0, behavior: "smooth" });
              }}
            >
              Blog
            </Link>

            <NavLink
              to="/contact"
              className={({ isActive }) => (isActive ? "activeLink" : "")}
              onClick={() => {
                setDrawerOpen(false);
                window.scrollTo({ top: 0, behavior: "smooth" });
              }}
            >
              Contact
            </NavLink>

            <SignUpCommonDialog
              title={"Sign Up"}
              className={"custom-btn"}
              callback={() => setDrawerOpen(false)}
            ></SignUpCommonDialog>
          </nav>
        </div>
      </Drawer>
    </>
  );
};

export default Header;
