import "./home.css";
import SEO from "../Seo";
import Pricing from "./components/Pricing";
import HeroBanner from "./components/HeroBanner";
import Features from "./components/Features";
import WhyChoose from "./components/whyChoose";
import Testimonials from "./components/Testimonials";
import CallToAction from "./components/CallToAction";

function Home() {
  return (
    <>
      <SEO page="home" /> {/* Load metadata for the home page */}
      <HeroBanner />
      <Features />
      <WhyChoose />
      <Pricing />
      {/* <Testimonials /> */}
      <CallToAction />
    </>
  );
}

export default Home;
