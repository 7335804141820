import axios from "axios";

export const postContactUs = async (data) => {
  return await axios.post(
    `${process.env.REACT_APP_BASE_URL}contact_us`,
    data,
    {
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    }
  );
};
