import * as React from "react";
import {
  Dialog,
  DialogContent,
  Typography,
  Box,
  Stepper,
  Step,
  StepLabel,
  DialogTitle,
  IconButton,
  Slide,
} from "@mui/material";
import Grid2 from "@mui/material/Grid2";
import CloseIcon from "@mui/icons-material/Close";
import {
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import toast from "react-hot-toast";
import useMediaQuery from "@mui/material/useMediaQuery";

import CompanyInfoComp from "./CompanyInfo";
import SelectPlanComp from "./SelectPlan";
import CheckoutComp from "./Checkout";
import LeftSideComp from "./LeftSideComp";
import { signUpSchema } from "../../schema/signUpSchema";
import { createTenant } from "../../services/createTenant.js";
import CommonDialog from "../dialog/CommonDialog.js";
import ThankYou from "./ThankYou.js";
import { clickEvent } from "../../utils/helpers/createEvent.js";

const steps = ["Company Info", "Select a Plan", "Checkout"];

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function SignUpComp({ open, handleClose, thankYouOpen }) {
  const isTablet = useMediaQuery("(max-width: 767px)");
  const stripe = useStripe();
  const elements = useElements();
  const [submitLoading, setSubmitLoading] = React.useState(false);

  const [activeStep, setActiveStep] = React.useState(0);
  const methods = useForm({
    resolver: yupResolver(signUpSchema),
    defaultValues: {},
    mode: "onBlur",
  });

  const handleSubmit = async (formData) => {
    setSubmitLoading(true);

    const eventData = {
      category: "SignUp",
      action: `SuccessFully Signed Up`,
      debug_mode: true,
    };

    clickEvent(eventData);

    if (methods.watch("plan_id") === "Free") {
      const selectedFreePlan = {
        category: "Select Free Plan",
        action: `Signed Up With Free Plan`,
        debug_mode: true,
      };

      clickEvent(selectedFreePlan);

      createTenant({ ...formData })
        .then((res) => {
          // toast.success(res.data.message);
          thankYouOpen(res.data);
          handleClose();
          setSubmitLoading(false);
        })
        .catch((err) => {
          console.log("error == ", err);
          toast.error(err.response.data.message);
          setSubmitLoading(false);
        });
    } else {
      if (!stripe || !elements) {
        toast.error("Stripe is not loaded");
        return;
      }
      const cardElement = await elements.getElement(CardNumberElement);
      if (!cardElement) {
        toast.error("Card not found");
        return;
      }

      stripe
        .createToken(cardElement)
        .then(async (data) => {
          let { token, error } = data;
          if (error) {
            toast.error(error.message);
            setSubmitLoading(false);
          } else {
            if (token?.id) {
              createTenant({ ...formData, card_token: token.id })
                .then((res) => {
                  // toast.success(res.data.message);
                  thankYouOpen(res.data);
                  handleClose();
                  setSubmitLoading(false);
                })
                .catch((err) => {
                  console.log("error == ", err);
                  toast.error(err.response.data.message);
                  setSubmitLoading(false);
                });
            }
          }
        })
        .catch((error) => {
          console.log("error occurred during card token creation ", error);
          toast.error(error);
        });
    }
  };

  const handleBack = () => {
    const data = {
      category: "Back Step",
      action: "Clicked Back",
      debug_mode: true,
    };
    if (activeStep == 1) {
      data.action = "Clicked Back from Select Plan";
      clickEvent(data);
    } else {
      data.action = "Clicked Back from Billing Details";
      clickEvent(data);
    }

    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <CompanyInfoComp
            activeStep={activeStep}
            handleBack={handleBack}
            setActiveStep={setActiveStep}
          />
        );
      case 1:
        return (
          <SelectPlanComp
            activeStep={activeStep}
            handleBack={handleBack}
            setActiveStep={setActiveStep}
            isLoading={submitLoading}
          />
        );
      case 2:
        return (
          <CheckoutComp handleBack={handleBack} isLoading={submitLoading} />
        );
      default:
        return "Unknown step";
    }
  };

  return (
    <>
      <Dialog
        fullScreen={isTablet} // Fullscreen for mobile and tablets
        fullWidth={isTablet} // FullWidth for better fit in these cases
        maxWidth={isTablet ? undefined : "md"}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        className="signup-popup"
        TransitionComponent={Transition}
      >
        <DialogContent sx={{ p: 0 }}>
          <Grid2 container spacing={0}>
            <LeftSideComp />
            <Grid2 size={{ md: 7, sm: 7, xs: 12 }} p={0}>
              <DialogTitle className="close-popup" sx={{ padding: "6px" }}>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handleClose}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>

              <div className="steps-column">
                <FormProvider {...methods}>
                  <form onSubmit={methods.handleSubmit(handleSubmit)}>
                    <div>
                      <Box className="step-cols-content">
                        {/* <Typography
                          variant="h2"
                          mb={2}
                          align="center"
                          className="web-text-hide"
                        >
                          Sign Up
                        </Typography> */}

                        <Stepper activeStep={activeStep} alternativeLabel>
                          {steps.map((label) => (
                            <Step key={label}>
                              <StepLabel>{label}</StepLabel>
                            </Step>
                          ))}
                        </Stepper>
                      </Box>
                      <div className="form-content">
                        {getStepContent(activeStep)}
                      </div>
                    </div>
                  </form>
                </FormProvider>
              </div>
            </Grid2>
          </Grid2>
        </DialogContent>
      </Dialog>
    </>
  );
}
