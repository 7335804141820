import React from "react";
import { Container, Stack, Link, Typography } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import XIcon from "@mui/icons-material/X";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";

import Button from "../Button";
import "./footer.css";
import SeeDemoComp from "../SeeDemo";
import { NavLink } from "react-router-dom";
import SignUpCommonDialog from "../singUpForm/SignUpCommonDialog";
import CommonDialog from "../dialog/CommonDialog";
import ThankYouDemoDialog from "../SeeDemo/ThankYouDemoDialog";
import { clickEvent } from "../../utils/helpers/createEvent";

const Footer = () => {
  const [open, setOpen] = React.useState(false);
  const [thankYou, setThankYou] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);

    const eventData = {
      category: "See Demo Form",
      action: `Clicked on See a Demo.`,
      debug_mode: true,
    }

    clickEvent(eventData);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleGetStarted = () => {
    const eventData = {
      category: "Get Started",
      action: `Clicked on Get Started.`,
      debug_mode: true,
    }

    clickEvent(eventData);
  }

  return (
    <>
      <footer className="footer" id="footer">
        <Container>
          <div className="top-header">
            <Typography variant="h2" style={{ textAlign: "center" }} mb={2.5}>
              Get Started With TaskBoard Today
            </Typography>
            <Stack direction="row" spacing={2} justifyContent="center">
              <SignUpCommonDialog
                title={"Start free trial"}
                className={"custom-btn"}
                callback={handleGetStarted}
              >
                <ArrowOutwardIcon />
              </SignUpCommonDialog>
              <Button
                buttonText={"See a Demo"}
                className={"custom-btn-outline"}
                handleClick={handleClickOpen}
              />
            </Stack>
            <p className="no-creditcard-text">No credit card required.</p>
          </div>
          <div className="bottom-footer">
            <p className="copyright">©2024 TaskBoard. All rights reserved.</p>
            <ul className="social-media">
              <li>
                <Link
                  href="https://www.facebook.com/profile.php?id=61574026070093"
                  target="_blank"
                  title="Facebook">
                  <FacebookIcon />
                </Link>
              </li>
              {/* <li>
                <Link href="#" title="Twitter">
                  <XIcon />
                </Link>
              </li>
              <li>
                <Link href="#" title="LinkedIn">
                  <LinkedInIcon />
                </Link>
              </li>
              <li>
                <Link href="#" title="Instagram">
                  <InstagramIcon />
                </Link>
              </li> */}
            </ul>
            <ul className="footer-nav">
              <li>
                <NavLink
                  to={"privacy-policy"}
                  onClick={() => {
                    window.scrollTo({ top: 0, behavior: "smooth" });
                  }}
                >
                  Privacy Policy
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={"terms"}
                  onClick={() => {
                    window.scrollTo({ top: 0, behavior: "smooth" });
                  }}
                >
                  Terms & Condition
                </NavLink>
              </li>
            </ul>
          </div>
        </Container>
      </footer>

      {thankYou && (
        <CommonDialog
          open={thankYou}
          handleClose={() => setThankYou(false)}
          title={"Thankyou"}
          className="thankyou-popup"
        >
          <ThankYouDemoDialog />
        </CommonDialog>
      )}

      {open && (
        <SeeDemoComp
          open={open}
          handleClose={handleClose}
          setThankYou={setThankYou}
        />
      )}
    </>
  );
};
export default Footer;
