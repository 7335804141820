import React from "react";
import SEO from "../Seo";
import { Container, Box, Typography } from "@mui/material";

function TermsConditions() {
  return (
    <>
      <SEO page="home" /> {/* Load metadata for the home page */}
      <Box component="section" className="policy-content" py={5}>
        <Container>
          <Typography className="page-heading" variant="h1" mb={3}>
            Terms & Conditions
          </Typography>
          <Typography variant="body1" mb={2}>
            <strong>Last Updated:</strong> Dec. 09, 2024
          </Typography>
          <Typography variant="body1" mb={2}>
            These Terms and Conditions ("Terms") govern your access to and use
            of TaskBoard’s services ("Service"). By using our Service, you agree
            to these Terms.
          </Typography>
          <Typography variant="h4" mb={2}>
            1. Definitions
          </Typography>
          <Typography variant="body1" mb={2}>
            <ul>
              <li>
                <strong>"User"</strong> refers to anyone who accesses or uses
                the Service.
              </li>
              <li>
                <strong>"Service"</strong> refers to the TaskBoard platform and
                related tools.
              </li>
              <li>
                <strong>"Pricing Plan"</strong> refers to TaskBoard’s fixed
                subscription fee of <strong> $49.99/month</strong> or{" "}
                <strong>$499.99/year</strong>, regardless of team size or user
                count.
              </li>
            </ul>
          </Typography>
          <Typography variant="h4" mb={2}>
            2. Eligibility
          </Typography>
          <Typography variant="body1" mb={2}>
            By using the Service, you represent that you are at least 18 years
            old and capable of entering into a legally binding agreement.
          </Typography>
          <Typography variant="h4" mb={2}>
            3. License to Use
          </Typography>
          <Typography variant="body1" mb={2}>
            TaskBoard grants you a limited, non-exclusive, non-transferable
            license to use the Service for your business or personal needs. You
            agree not to:
          </Typography>
          <Typography variant="body1" mb={2}>
            <ul>
              <li>
                Reverse-engineer, modify, or create derivative works of the
                Service
              </li>
              <li>Use the Service for any unlawful purpose</li>
            </ul>
          </Typography>
          <Typography variant="h4" mb={2}>
            4. User Responsibilities
          </Typography>
          <Typography variant="body1" mb={2}>
            You are responsible for:
          </Typography>
          <Typography variant="body1" mb={2}>
            <ul>
              <li>Providing accurate registration information</li>
              <li>
                Maintaining the confidentiality of your account credentials
              </li>
              <li>All activities that occur under your account</li>
            </ul>
          </Typography>
          <Typography variant="h4" mb={2}>
            5. Payment Terms
          </Typography>
          <Typography variant="body1" mb={2}>
            TaskBoard operates on a fixed pricing model:
          </Typography>
          <Typography variant="body1" mb={2}>
            <ul>
              <li>
                <strong>Monthly Subscription:</strong> $49.99 per company
              </li>
              <li>
                <strong>Annual Subscription:</strong> $499.99 per company
                (savings of $100 per year)
              </li>
            </ul>
          </Typography>
          <Typography variant="body1" mb={2}>
            Payments are non-refundable except as required by law. Failure to
            pay on time may result in suspension or termination of your account.
          </Typography>
          <Typography variant="h4" mb={2}>
            6. Intellectual Property
          </Typography>
          <Typography variant="body1" mb={2}>
            All content, features, and functionality provided by TaskBoard,
            including trademarks, logos, and software, are the property of
            TaskBoard. You may not reproduce or distribute TaskBoard’s materials
            without prior written consent.
          </Typography>
          <Typography variant="h4" mb={2}>
            7. Prohibited Activities
          </Typography>
          <Typography variant="body1" mb={2}>
            You agree not to:
          </Typography>
          <Typography variant="body1" mb={2}>
            <ul>
              <li>Violate any applicable laws or regulations</li>
              <li>Interfere with the operation of the Service</li>
              <li>Upload viruses or malicious software</li>
            </ul>
          </Typography>
          <Typography variant="h4" mb={2}>
            8. Termination
          </Typography>
          <Typography variant="body1" mb={2}>
            We reserve the right to terminate or suspend your account if you
            violate these Terms or fail to make payments under our fixed pricing
            plan.
          </Typography>
          <Typography variant="h4" mb={2}>
            9. Limitation of Liability
          </Typography>
          <Typography variant="body1" mb={2}>
            To the maximum extent permitted by law, TaskBoard is not liable for:
          </Typography>
          <Typography variant="body1" mb={2}>
            <ul>
              <li>Indirect, incidental, or consequential damages</li>
              <li>Loss of profits or revenue</li>
            </ul>
          </Typography>
          <Typography variant="h4" mb={2}>
            10. Indemnification
          </Typography>
          <Typography variant="body1" mb={2}>
            You agree to indemnify TaskBoard and its affiliates against any
            claims, damages, or expenses arising out of your use of the Service.
          </Typography>
          <Typography variant="h4" mb={2}>
            11. Governing Law
          </Typography>
          <Typography variant="body1" mb={2}>
            These Terms are governed by the laws of [Your Jurisdiction], without
            regard to its conflict of law provisions.
          </Typography>
          <Typography variant="h4" mb={2}>
            12. Changes to Terms
          </Typography>
          <Typography variant="body1" mb={2}>
            TaskBoard may revise these Terms at any time. If changes affect our
            pricing plan, we will notify you in advance. Continued use of the
            Service after changes constitutes acceptance of the updated Terms.
          </Typography>
        </Container>
      </Box>
    </>
  );
}

export default TermsConditions;
