import { Grid2, Typography } from "@mui/material";
import React from "react";

function LeftSideComp() {
  return (
    <Grid2 size={{ md: 5, sm: 5, xs: 12 }} className="left-dialog-grid">
      <div className="left-info-panel-dialog">
        <Typography variant="h2" mb={1}>
          Sign Up
        </Typography>
        <Typography className="lead-text" variant="body1" mb={3}>
          Sign up now and discover how TaskBoard can revolutionize your
          workflow:
        </Typography>
        <ul>
          <li>
            <strong>Simple Signup:</strong> Provide your company details, select
            a subscription plan (including a 30-day free trial with no credit
            card required), and complete payment if applicable.
          </li>
          <li>
            <strong>Instant Setup:</strong> Your personalized TaskBoard will be
            created immediately, tailored to your organization’s needs.
          </li>
          <li>
            <strong>Quick Access:</strong> You'll receive an email with all the
            details to log in, invite team members, and start managing projects
            right away.
          </li>
        </ul>
        <Typography className="lead-text" variant="body1">
          Get ready to experience a smoother, more efficient project management
          process!
        </Typography>
      </div>
    </Grid2>
  );
}

export default LeftSideComp;
