import React from "react";
import SEO from "../Seo";
import { Container, Box, Stack, Typography, Grid2 } from "@mui/material";
import CallToAction from "../home/components/CallToAction";
import TimeTrackingImage from "../../assets/images/Billing-Worklog.png";
import BenefitTBImage from "../../assets/images/board.png";
import WorkflowTBImage from "../../assets/images/workflow.png";
import SignUpCommonDialog from "../../components/singUpForm/SignUpCommonDialog";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import { clickEvent } from "../../utils/helpers/createEvent";
import { Link } from "react-router-dom";
import "./About.css";

const About = () => {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleCallback = () => {
    const eventData = {
      category: "Start Free Trial",
      action: `Clicked on Start your Free Trial.`,
      debug_mode: true,
    };

    clickEvent(eventData);
  };

  return (
    <>
      <SEO page="about" /> {/* Load metadata for the about page */}
      <Box component="section" className="about-us" py={8}>
        <Container>
          <Typography className="page-heading" variant="h1" mb={3}>
            About Taskboard: A Project Management Solution Designed for
            Collaboration
          </Typography>
          <Typography variant="body1" mb={2}>
            Taskboard was originally built by{" "}
            <a href="http://www.sofmen.com/" target="_blank">
              Sofmen
            </a>{" "}
            to manage our internal projects. At Sofmen, we juggle 15 to 25
            projects at any given time. We needed a project management solution
            tailored to our workflow rather than using generic tools like JIRA.
            What we built was Taskboard—a platform designed for our needs,
            providing detailed time tracking and a streamlined user experience
            to stay organized and efficient.
          </Typography>
          <Typography variant="body1" mb={2}>
            As we began using Taskboard internally, we realized that it wasn’t
            just us who could benefit from it. When we shared the application
            with other agencies, they loved it and quickly adopted it into their
            operations. That’s when we had an idea: Why not make Taskboard
            available to the public? What started as an internal tool has now
            become a platform designed to help businesses of all types and sizes
            manage their projects with ease.
          </Typography>
          <SignUpCommonDialog
            title={"Get Started"}
            className={"custom-btn"}
            callback={handleCallback}
          >
            <ArrowOutwardIcon />
          </SignUpCommonDialog>
        </Container>
      </Box>
      <Box component="section" className="how-tb-different grey-bg" py={8}>
        <Container>
          <Typography className="" variant="h2" mb={2}>
            How Taskboard Is Different
          </Typography>
          <Typography className="body1" mb={2}>
            Taskboard stands out in a crowded field of project management tools.
            Here's why:
          </Typography>
          <Typography className="body1" mb={2}>
            <strong>A Simple, Predictable Approach to Collaboration</strong>
          </Typography>
          <Typography className="body1" mb={2}>
            At TaskBoard, we understand that effective collaboration is the
            cornerstone of any successful project. That’s why we offer a fixed,
            flat pricing structure that encourages inclusivity and removes the
            barriers that often come with user-based pricing models. Unlike many
            competitors that charge per user, our pricing is designed to ensure
            that everyone—whether they’re part of the core team or key
            stakeholders—is able to contribute fully to your project, without
            the need to worry about additional costs for adding more users.
          </Typography>
          <Typography className="body1" mb={2}>
            With our straightforward pricing plan, you get access to all the
            features TaskBoard offers—whether you’re a small startup or a
            growing enterprise—without worrying about hidden fees or unexpected
            costs. The flat fee means that there are no restrictions on who can
            participate in the project management process. Every team member,
            collaborator, or stakeholder can join the board, contribute to
            discussions, track progress, and share updates, all without being
            limited by seat costs or user caps.
          </Typography>
          <Typography className="body1" mb={2}>
            This flexible, all-inclusive approach ensures that your engine team
            can collaborate seamlessly, and that no one is left out due to
            budget constraints or access limitations. With TaskBoard,
            collaboration truly knows no boundaries.
          </Typography>
          <Grid2
            container
            alignItems="center"
            mt={4}
            spacing={{ sm: 4, xs: 0 }}
          >
            <Grid2 className="order2" size={{ sm: 6, xs: 12 }}>
              <Typography variant="h3" mb={1.5}>
                Comprehensive Time Tracking
              </Typography>
              <Typography variant="body1" mb={3}>
                Our application offers more detailed time tracking than most
                project management tools, making it perfect for agencies and
                businesses that need to track billable hours, project progress,
                and employee productivity with precision.
              </Typography>
            </Grid2>
            <Grid2 className="order1" size={{ sm: 6, xs: 12 }}>
              <div className="about-graphic">
                <img src={TimeTrackingImage} />
              </div>
            </Grid2>
          </Grid2>
          <Grid2
            container
            alignItems="center"
            mt={4}
            spacing={{ sm: 4, xs: 0 }}
          >
            <Grid2 className="order1" size={{ sm: 6, xs: 12 }}>
              <div className="about-graphic">
                <img src={BenefitTBImage} />
              </div>
            </Grid2>
            <Grid2 className="order2" size={{ sm: 6, xs: 12 }}>
              <Typography variant="h3" mb={1.5}>
                Customizable and Adaptable
              </Typography>
              <Typography variant="body1" mb={3}>
                We understand that every business is unique. That's why
                Taskboard is built to be customizable. If you need features or
                integrations that are specific to your company’s workflow, we’re
                here to help. Let us know what you need, and we'll work with you
                to make it happen. After all, web and app development is our
                core business.
              </Typography>
            </Grid2>
          </Grid2>
        </Container>
      </Box>
      <Box component="section" className="why-tb-choose" py={8}>
        <Container>
          <Typography variant="h2" mb={2}>
            {" "}
            Why We Chose Flat Pricing
          </Typography>
          <Typography variant="body1" mb={2}>
            At Taskboard, we believe in making collaboration easy and accessible
            for everyone. That’s why we’ve chosen a flat pricing model, which
            sets us apart from other project management tools that charge on a
            per-user basis.
          </Typography>
          <Typography variant="body1" mb={2}>
            We understand that in many businesses, there are different types of
            users—some are active participants who log in daily, while others
            may only need to check in occasionally or view project updates.
            Traditional pricing models often charge per user, which can make it
            expensive for organizations with varying levels of usage across
            their team. With Taskboard’s flat pricing, we’ve eliminated that
            barrier
          </Typography>
          <Typography variant="body1" mb={3}>
            Our goal is to promote <strong>project collaboration</strong>{" "}
            without the worry of per-seat fees. Whether you're a small startup
            or a large organization with hundreds of users, the cost remains the
            same. This ensures that all team members—whether they’re daily users
            or occasional contributors—can participate fully without financial
            concerns holding them back.
          </Typography>
          <SignUpCommonDialog
            title={"Join Us Today"}
            className={"custom-btn"}
            callback={handleCallback}
          >
            <ArrowOutwardIcon />
          </SignUpCommonDialog>
        </Container>
      </Box>
      <Box component="section" className="who-tb-benefits grey-bg" py={8}>
        <Container>
          <Typography variant="h2" mb={2}>
            {" "}
            Who Can Benefit from Taskboard?
          </Typography>
          <Typography variant="body1" mb={2}>
            Taskboard isn’t just for software development companies. It’s
            designed to help any organization track and manage their internal
            tasks. Whether you’re a small startup, a non-profit organization, or
            a large enterprise, Taskboard can scale with you.
          </Typography>
          <Typography variant="body1" mb={2}>
            Some potential users include:
          </Typography>
          <Grid2 container alignItems="center" spacing={{ sm: 4, xs: 0 }}>
            <Grid2 className="order2" size={{ sm: 6, xs: 12 }}>
              <ul>
                <li>
                  <strong>Marketing Agencies:</strong> Track campaigns, client
                  work, and team progress.
                </li>
                <li>
                  <strong>Consulting Firms:</strong> Manage multiple client
                  projects, deadlines, and deliverables.
                </li>
                <li>
                  <strong>Non-Profit Organizations:</strong> Coordinate
                  volunteers, events, and donations.
                </li>
                <li>
                  <strong>Creative Agencies:</strong> Keep track of design,
                  content production, and client approvals.
                </li>
                <li>
                  <strong>Educational Institutions:</strong> Organize projects,
                  resources, and faculty collaboration.
                </li>
                <li>
                  <strong>Event Planning Companies:</strong> Manage timelines,
                  budgets, and vendors.
                </li>
                <li>
                  <strong>Healthcare Providers:</strong> Oversee projects like
                  facility upgrades, patient care plans, and regulatory
                  compliance.
                </li>
              </ul>
              <SignUpCommonDialog
                title={"Join Us Today"}
                className={"custom-btn"}
                callback={handleCallback}
              >
                <ArrowOutwardIcon />
              </SignUpCommonDialog>
            </Grid2>
            <Grid2 className="order1" size={{ sm: 6, xs: 12 }}>
              <div className="about-graphic">
                <img src={WorkflowTBImage} alt="" />
              </div>
            </Grid2>
          </Grid2>
        </Container>
      </Box>
      <Box component="section" className="feedback-tb" py={8}>
        <Container>
          <Typography variant="h2" mb={2}>
            {" "}
            Your Feedback Matters: Help Shape Taskboard’s Future
          </Typography>
          <Typography variant="body1" mb={3}>
            Taskboard is continuously evolving. We value your input and would
            love to hear what features you’d like to see next. Whether it’s a
            new functionality or a custom integration for your specific business
            needs, we’re here to help. If you’d like us to build something
            tailored just for you, feel free to reach out. As web and app
            developers, we’re passionate about creating solutions that help
            businesses thrive.
          </Typography>
          <div>
            <Link to="/contact" className={""} style={{ color: "#1976d2" }}>
              Contact Us
            </Link>
          </div>
        </Container>
      </Box>
    </>
  );
};

export default About;
