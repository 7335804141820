import React from 'react'
import {
  DialogActions,
  DialogTitle,
  Typography,
  IconButton,
  Stack,
  Grid2,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

// import Button from "./Button";
import { seeDemoSchema } from "../../schema/seeDemoSchema";
import FormTextField from "../formElements/textfield";
import { FormSelect } from "../formElements/select";
import { getDemo } from "../../services/createTenant";
import toast from 'react-hot-toast';
import { clickEvent } from '../../utils/helpers/createEvent';

function DemoForm({ handleClose, setThankYou, onClose, fullScreen }) {
  const [submitLoading, setSubmitLoading] = React.useState(false);
  const methods = useForm({
    resolver: yupResolver(seeDemoSchema),
    defaultValues: {},
    mode: "onBlur",
  });

  const handleSubmit = async (formData) => {
    setSubmitLoading(true);
    console.log("formdata == ", formData);
    getDemo(formData)
      .then((res) => {
        console.log(res);
        setThankYou(true);
        const eventData = {
          category: "Demo Form",
          action: `Demo Form Submitted Successfully.`,
          debug_mode: true,
        }

        clickEvent(eventData);
        handleClose();
      })
      .catch((err) => {
        toast.error(err.response.data.message)
        console.log(err);
      })
      .finally(() => {
        setSubmitLoading(false);
      });
  };
  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleSubmit)}>
        <Grid2 container spacing={0}>
          <Grid2
            size={{ sm: 6, xs: 12 }}
            className="left-dialog-grid"
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: fullScreen ? "#f5f5f5" : "#fff",
            }}
          >
            <div className="left-info-panel-dialog">
              <Typography variant="h2" mb={1}>
                See a Demo
              </Typography>
              <Typography className="lead-text" variant="body1" mb={3}>
                Experience how TaskBoard streamlines project management, links
                tasks to objectives, unites teams, automates workflows, and lets
                you explore preloaded tickets, milestones, and reports in a
                secure demo.
              </Typography>
              <Typography
                variant="h3"
                mb={2}
                style={{ fontSize: "20px", fontWeight: "600" }}
              >
                What to Expect:
              </Typography>
              <ul>
                <li>
                  After filling out the form, you’ll receive an email with your
                  demo access link.
                </li>
                <li>
                  Try features like creating tickets, inviting users, and
                  exploring reports.
                </li>
                <li>
                  Your demo is temporary and will reset once you're done
                  exploring.
                </li>
              </ul>
              <Typography variant="body1" className="lead-text">
                Start your personalized TaskBoard demo today!
              </Typography>
            </div>
          </Grid2>
          <Grid2
            size={{ sm: 6, xs: 12 }}
            sx={{
              p: {
                md: 5,
                xs: 3,
              },
            }}
            className="seeDemo-content-wrapper"
          >
            <div className="seeDemo-form">
              <Typography variant="h2" mb={0} className="web-text-hide">
                See a Demo
              </Typography>
              <DialogTitle className="close-popup">
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={onClose}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <Typography variant="h6" mb={3}>
                To get started, please fill the below information.
              </Typography>
              <Stack spacing={2}>
                <FormTextField
                  margin="dense"
                  id="cname"
                  name="company_name"
                  label="Company Name"
                  type="text"
                  fullWidth
                />
                <FormTextField
                  margin="dense"
                  id="name"
                  name="email"
                  label="Email Address"
                  type="email"
                  fullWidth
                />
                {/* <FormSelect name="industry_type" label="Industry Type">
                        <MenuItem value={"ItSoftware"}>IT Software</MenuItem>
                        <MenuItem value={"Healthcare"}>Healthcare</MenuItem>
                        <MenuItem value={"Finance"}>Finance</MenuItem>
                      </FormSelect> */}
                <DialogActions
                  sx={{
                    padding: 0,
                    marginTop: 28,
                  }}
                >
                  <Button variant="outlined" onClick={onClose}>
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={submitLoading}
                  >
                    Submit
                  </Button>
                </DialogActions>
              </Stack>
            </div>
          </Grid2>
        </Grid2>
      </form>
    </FormProvider>
  );
}

export default DemoForm