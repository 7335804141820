import React, { useState } from "react";
import SEO from "../Seo";
import {
  Container,
  Typography,
  Box,
  Button,
  Grid2,
  Paper,
} from "@mui/material";
import "./Contact.css";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { contactUsSchema } from "../../schema/contactUsSchema";
import FormTextField from "../../components/formElements/textfield";
import { postContactUs } from "../../services/contactUs";
import toast from "react-hot-toast";

const Contact = () => {
  const [isLoading, setIsLoading] = useState(false);
  const methods = useForm({
    resolver: yupResolver(contactUsSchema),
    defaultValues: { subject: "", phone: "", name: "", message: "", email: "" },
    mode: "onBlur",
  });

  const handleSubmit = (data) => {
    setIsLoading(true);

    const filteredData = Object.fromEntries(
      Object.entries(data).filter(
        ([_, value]) => value !== null && value !== ""
      )
    );

    postContactUs(filteredData)
      .then((res) => {
        toast.success(res.data.message);
        methods.reset();
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("error === ", err);
        toast.error(err.response.data.message);
      });
  };
  return (
    <>
      <SEO page="home" />
      <Box className="banner-page">
        <Container>
          <Typography variant="h1" className="page-heading" mb={1}>
            Contact Us
          </Typography>
          <Typography variant="subtitle1" sx={{ mb: 0 }}>
            We'd love to hear from you! Get in touch with us.
          </Typography>
          <Paper className="contact-form" elevation={3} sx={{ p: 3 }}>
            {/* <Box component="form" onSubmit={handleSubmit}> */}
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(handleSubmit)}>
                <Grid2 container spacing={3}>
                  <Grid2 item size={{ sm: 6, xs: 12 }}>
                    <FormTextField name={"name"} label="Name*" />
                  </Grid2>
                  <Grid2 item size={{ sm: 6, xs: 12 }}>
                    <FormTextField name={"email"} label="Email*" />
                  </Grid2>
                  <Grid2 item size={{ sm: 6, xs: 12 }}>
                    <FormTextField name={"phone"} label="Phone" />
                  </Grid2>
                  <Grid2 item size={{ sm: 6, xs: 12 }}>
                    <FormTextField name={"subject"} label="Subject*" />
                  </Grid2>
                  <Grid2 item size={{ sm: 12, xs: 12 }}>
                    <FormTextField
                      name={"message"}
                      label="Message"
                      multiline
                      rows={4}
                    />
                  </Grid2>
                </Grid2>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ mt: 3, px: 4, py: 1.5 }}
                  type="submit"
                  size="medium"
                  loading={isLoading}>
                  Submit
                </Button>
              </form>
            </FormProvider>
          </Paper>
        </Container>
      </Box>
    </>
  );
};

export default Contact;
